import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoBoxItem from "../components/InfoBoxItem";
import styles from "../css/ModalUsers.module.css";

const ModalUsers = ({
  userId,
  title,
  userRegistered,
  userValue,
  shops,
  onClose,
  onConfirm,
  confirmText,
  cancelText,
  isDelete,
  onDelete,
  getUserStage,
  formatDate,
  gclid,
}) => {
  const { t } = useTranslation();

  // Pokud je userValue 0, považujeme hodnotu za nenastavenou a
  // inicializujeme stage jako prázdný řetězec
  const initialStage = userValue === 0 ? "" : userValue.toString();

  const [stage, setStage] = useState(initialStage);
  const [modified, setModified] = useState(false);
  const [shopFilter, setShopFilter] = useState("");

  useEffect(() => {
    setStage(initialStage);
    setModified(false);
  }, [initialStage]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [onClose]);

  const handleStageChange = (e) => {
    setStage(e.target.value);
    setModified(e.target.value !== initialStage);
  };

  const handleUpdate = () => {
    onConfirm(userId, stage);
  };

  const handleShopFilterChange = (e) => {
    setShopFilter(e.target.value);
  };

  // Filtrování obchodů dle zadaného textu
  const filteredShops = shops.filter((shop) => {
    const filterLower = shopFilter.toLowerCase();
    return (
      (shop.website && shop.website.toLowerCase().includes(filterLower)) ||
      (shop.first_name &&
        shop.first_name.toLowerCase().includes(filterLower)) ||
      (shop.last_name && shop.last_name.toLowerCase().includes(filterLower)) ||
      (shop.email && shop.email.toLowerCase().includes(filterLower))
    );
  });

  return (
    <div className={styles.container} onClick={onClose}>
      <div className={styles.modal_box} onClick={(e) => e.stopPropagation()}>
        <div className={styles.header}>
          <div className={styles.userInfo}>
            <h2>{title}</h2>
          </div>
          <div className={styles.selectRow}>
            <label htmlFor="stageSelect">Hodnota:</label>
            <select
              id="stageSelect"
              value={stage}
              onChange={handleStageChange}
              className={styles.selectInput}
            >
              {/* Pokud ještě není nastavena hodnota, zobrazíme placeholder */}
              {initialStage === "" && (
                <option value="" disabled>
                  nenastaven
                </option>
              )}
              <option value="0.1">0.1</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
        {userRegistered && (
          <p>
            <strong>Registrace:</strong> {userRegistered}
          </p>
        )}
        {/* Pokud je userValue nenastaven (0), zobrazíme info */}
        {userValue === 0 && <p>Lead nenastaven</p>}
        <div className={styles.shopHeader}>
          <h3>Obchody</h3>
          <input
            type="text"
            placeholder="Filtrovat..."
            value={shopFilter}
            onChange={handleShopFilterChange}
            className={styles.shopSearch}
          />
        </div>
        <div className={styles.textBox}>
          {filteredShops && filteredShops.length > 0 ? (
            filteredShops.map((shop) => (
              <div key={shop.uuid} className={styles.shopDetail}>
                <InfoBoxItem
                  label="Název"
                  value={shop.name || "Nepojmenovaný obchod"}
                />
                {shop.website && (
                  <InfoBoxItem label="Web" value={shop.website} />
                )}
                <InfoBoxItem label="UUID" value={shop.uuid} />
                {shop.status_create && (
                  <InfoBoxItem
                    label="Stav obchodu"
                    value={
                      shop.status_create === "step1"
                        ? "1. krok registrace"
                        : shop.status_create === "billinfo"
                        ? "2. krok registrace"
                        : shop.status_create === "shopdata"
                        ? "3. krok registrace"
                        : shop.status_create === "account"
                        ? "4. krok registrace"
                        : shop.status_create === "pixel"
                        ? "5. krok registrace"
                        : shop.status_create === "end"
                        ? "dokončen"
                        : "není nastaveno"
                    }
                  />
                )}
                {shop.email && <InfoBoxItem label="Email" value={shop.email} />}
                {shop.phone && (
                  <InfoBoxItem label="Telefon" value={shop.phone} />
                )}
                {shop.ico && <InfoBoxItem label="IČO" value={shop.ico} />}
                <div className={styles.address}>
                  {shop.street && (
                    <InfoBoxItem label="Ulice" value={shop.street} />
                  )}
                  {shop.postal_code && (
                    <InfoBoxItem label="PSČ" value={shop.postal_code} />
                  )}
                  {shop.city && <InfoBoxItem label="Město" value={shop.city} />}
                  {shop.country && (
                    <InfoBoxItem label="Země" value={shop.country} />
                  )}
                </div>
                {shop.first_stat_date && (
                  <InfoBoxItem
                    label="První statistika"
                    value={shop.first_stat_date}
                  />
                )}
                {shop.start_date && (
                  <InfoBoxItem
                    label="Start date"
                    value={formatDate(shop.start_date)}
                  />
                )}
                {shop.end_date && (
                  <InfoBoxItem
                    label="End date"
                    value={formatDate(shop.end_date)}
                  />
                )}
                <hr />
              </div>
            ))
          ) : (
            <p className={styles.nothing}>Nic jsem nenašel...</p>
          )}
        </div>
        <div className={styles.btn_box}>
          <button className={styles.btn} onClick={onClose}>
            {cancelText || t("modalConfirmChange.cancel")}
          </button>
          <button className={styles.btn} onClick={handleUpdate}>
            {confirmText || "Upravit"}
          </button>
          {isDelete && (
            <button
              className={`${styles.btn} ${styles.delete}`}
              onClick={(e) => {
                e.target.blur();
                onDelete();
              }}
            >
              Vypnout
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalUsers;
