import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import axiosInstance from "../api/AxiosConfig";
import styles from "../css/Users.module.css";
import ModalUsers from "../components/ModalUsers";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();
  const { setIsUserDataLoading } = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("");

  // Klíče pro filtrování – nyní zahrnují i „nenastaven“
  const [selectedStages, setSelectedStages] = useState({
    "nenastaven": true,
    "0.1": true,
    "10": true,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [shops, setShops] = useState([]);
  const [statusText, setStatusText] = useState("Načítám uživatele...");

  useEffect(() => {
    getUsers();
  }, []);

  // Filtrování dle emailu a podle hodnoty user_value
  useEffect(() => {
    const filtered = users
      .filter((user) => {
        if (!user.email.toLowerCase().includes(filter.toLowerCase())) {
          return false;
        }
        const stage = getUserStage(user.user_value);
        return selectedStages[stage];
      })
      .sort((a, b) => a.email.localeCompare(b.email));
    setFilteredUsers(filtered);
  }, [filter, users, selectedStages]);

  // Načtení uživatelů z API
  const getUsers = async () => {
    try {
      const response = await axiosInstance.get("users/");
      const sortedUsers = response.data.sort((a, b) =>
        a.email.localeCompare(b.email)
      );
      setUsers(sortedUsers);
    } catch (error) {
      console.log("Error getting users!", error);
      setStatusText("Nic jsem nenašel");
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Načtení detailů uživatele (obchody apod.)
  const getUserDetails = async (userId) => {
    setIsUserDataLoading(true);
    try {
      const response = await axiosInstance.get(`user/detail/${userId}/`);
      setShops(response.data.shops || []);
    } catch (error) {
      console.log("Error getting user details!", error);
      setShops([]);
    } finally {
      setIsUserDataLoading(false);
    }
  };

  // Převod user_value na odpovídající textový řetězec
  // Pokud je hodnota 0, vracíme "nenastaven"
  const getUserStage = (value) => {
    if (value === 0) return "nenastaven";
    if (value === 0.1) return "0.1";
    if (value === 10) return "10";
    return "";
  };

  // Převod textové volby z modalu na číselnou hodnotu pro PATCH request
  const stageToValue = (stage) => {
    switch (stage) {
      case "0.1":
        return 0.1;
      case "10":
        return 10;
      default:
        return 0;
    }
  };

  // PATCH request pro aktualizaci hodnoty user_value
  const onUpdateValue = async (id, newStage) => {
    setIsUserDataLoading(true);
    try {
      const numericValue = stageToValue(newStage);
      await axiosInstance.patch(`users/${id}/`, {
        user_value: numericValue,
        send_conversion_action: selectedUser?.gclid ? true : false,
      });
      setIsModalOpen(false);
      getUsers();
    } catch (error) {
      console.log("Error updating user value", error);
    } finally {
      setIsUserDataLoading(false);
      setUsers([]);
    }
  };

  // Otevření modalu pro detail/úpravu uživatele
  const handleUserClick = (user) => {
    setSelectedUser(user);
    getUserDetails(user.id);
    setIsModalOpen(true);
  };

  const formatDate = (dateStr) =>
    new Intl.DateTimeFormat("cs-CZ", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }).format(new Date(dateStr));

  // Filtrování obchodů pro vybraného uživatele
  const userShops = selectedUser
    ? shops.filter((shop) => shop.users.some((u) => u.id === selectedUser.id))
    : [];

  const handleStageChange = (e) => {
    const { value, checked } = e.target;
    setSelectedStages((prev) => ({
      ...prev,
      [value]: checked,
    }));
  };

  return (
    <>
      <section className={`${"need-mt"} ${styles.usersSection}`}>
        <h2 className={styles.title}>Vyberte uživatele</h2>
        <div className={styles.sortAndSearchContainer}>
          <input
            type="text"
            placeholder="Vyhledávání"
            className={styles.searchInput}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div className={styles.userListBox}>
          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <div
                key={user.id}
                className={styles.userRow}
                onClick={() => handleUserClick(user)}
              >
                <div className={styles.nlBox}>
                  <span className={styles.userEmail}>{user.email}</span>
                  {user?.gclid && (
                    <span className={styles.gclidLabel}>Google Ads</span>
                  )}
                </div>
                {(user.user_value || user.user_value === 0) && (
                  <span className={styles.userValue}>
                    {getUserStage(user.user_value)}
                  </span>
                )}
              </div>
            ))
          ) : (
            <p className={styles.noUsers}>{statusText}</p>
          )}
        </div>
        <div className={styles.checkboxContainer}>
          {["nenastaven", "0.1", "10"].map((stageOption) => (
            <label
              key={stageOption}
              className={`checkbox ${styles.checkboxLabel}`}
            >
              <input
                type="checkbox"
                name="stage"
                value={stageOption}
                checked={selectedStages[stageOption]}
                onChange={handleStageChange}
              />
              {stageOption}
              <span className="checkbox__checker"></span>
            </label>
          ))}
        </div>
      </section>

      {isModalOpen && selectedUser && (
        <ModalUsers
          userId={selectedUser.id}
          title={selectedUser.email}
          userValue={selectedUser.user_value}
          userRegistered={formatDate(selectedUser.registered_at)}
          shops={userShops}
          onClose={() => setIsModalOpen(false)}
          onConfirm={onUpdateValue}
          confirmText=""
          cancelText="Zavřít"
          getUserStage={getUserStage}
          formatDate={formatDate}
          gclid={selectedUser.gclid}
        />
      )}
    </>
  );
};

export default Users;
